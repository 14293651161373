@font-face {
  font-family: "nitemare";
  src: url("./assets/typo/NITEMARE.woff2") format("woff2"),
         url("./assets/typo/NITEMARE.woff") format("woff");
 }

body {
  overflow: hidden;
  font-family: "nitemare";
  filter: drop-shadow(0 0 10px white);
}

text {
  font-family: "nitemare" !important;
  font-size: 120% !important;
  max-width: 100px !important;
  white-space: wrap !important;
  color: #000 !important;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  cursor: url(./assets/cursor/dogwhite.png), auto;

}

.Stat {
  background-color: #000;
}

.cursor {
  height: 50px;
  width: auto;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;
}

.Persos g rect:nth-of-type(1) {
  fill: rgb(199, 199, 199) !important;
  cursor: pointer;
}


.Persos text {
  writing-mode : vertical-rl;
  font-size: 1.5rem !important;
  transform: translateY(-12%);
  letter-spacing: 2px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .active {
    animation: App-logo-spin 4s ease-in;
  }

  .activeVideo {
    animation: circle 3s ease-in 2s;
  }

  .activeLogo {
    animation: logo-spin 3s ease-in;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(4);
  }
}

@keyframes logo-spin {
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(.1);
  }
}

@keyframes circle {
  from {
    transform: translate(-50%, -50%) scale(0);
  } to {
    transform: translate(-50%, -50%) scale(10);
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: #000;
  z-index: 3;
}

.link {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  transition: all .5s ease;
}

.logo:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.graphContainer {
  height: 100vh;
  width: 100vw;
  transform: scale(1);
  transition: all 1s ease-in-out;
}

.graphContainerActive {
  height: 100vh;
  width: 100vw;
  transform: scale(1.1);
}

.desc {
  position: absolute;
  bottom: 10%;
  right: 10%;
  max-width: 20%;
  z-index: 3;
  transition: all 1s ease;
  color: #fff;
  font-size: 1.2rem;
}

.descPersos {
  position: absolute;
  bottom: 10%;
  right: 5%;
  max-width: 20%;
  z-index: 3;
  transition: all 1s ease;
  color: #fff;
  font-size: 1.2rem;
}

.votes {
  position: absolute;
  bottom: 5%;
  left: 5%;
  max-width: 20%;
  z-index: 3;
  transition: all 1s ease;
  color: #fff;
  font-size: 1.2rem;
}

.votesActive {
  left: -50%;
  bottom: -50%;
}


.bridge {
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 50%;
  height: auto;
  z-index: 2;
  transition: all 1s ease;
  pointer-events: none;
}

.bridgeActive {
  right: -50%;
  bottom: -50%;
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
  transition-delay: 5s;
}

.next {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 5%;
  right: 5%;
  z-index: 3;
  transition: all 1s ease;
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.nextActive {
  right: -50%;
  top: -50%;
}

html.is-animating .transition-fade {
  opacity: 0;
}

span {
  text-transform: uppercase;
  letter-spacing: 2px;
}

circle:first-of-type {
  filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.7));
  fill: #fff5eb !important;
}

circle {
  fill: #fff5eb;
  cursor: pointer;
}

circle:nth-child(-n+9) {
  fill: rgb(223, 223, 223);
}


.collines {
  position: absolute;
  bottom: -25%;
  left: -5%;
  width: 100%;
  height: auto;
  z-index: 2;
  transition: all 1s ease;
  pointer-events: none;
}

.moon {
  position: absolute;
  top: 5%;
  left: 10%;
  width: 200px;
  height: 200px;
  z-index: 0;
  background: transparent radial-gradient(closest-side at 50% 50%, #9D8B8B 0%, #B0A58D 70%, #C8C198 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 5px 3px 50px #FFFFFFDD, 10px 10px 50px #FFFFFF64;
  border: 1px solid #707070;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moonPersos {
  opacity: 0;
  position: absolute;
  height: 150px;
  width: auto;
  transition: all 0.5s ease;
}

